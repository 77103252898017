<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="page-title-right d-flex">
            <div class="me-2 d-flex">
              <label class="col-form-label me-2">Year</label>
              <div style="width: 150px">
                <v-select
                  label="name"
                  v-model="year"
                  :options="years"
                  :selectable="(options) => isSelectableYear(options)"
                  :clearable="false"
                  :reduce="(name) => name.id"
                  @option:selected="getPlansByYear($event)"
                >
                </v-select>
              </div>
            </div>
            <label class="col-form-label me-2">Month</label>
            <div style="width: 165px">
              <v-select
                label="name"
                v-model="month"
                :options="months"
                :selectable="(options) => isSelectableMonth(options)"
                :clearable="false"
                :reduce="(name) => name.id"
                @option:selected="getPlansByMonth($event)"
              >
              </v-select>
            </div>

            <div @click="refreshData" class="icon-css">
              <i style="color: #4a81d4" class="fe-rotate-cw"></i>
            </div>
          </div>
          <h4 class="page-title">Presentation Assessment</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <!-- advance serach -->
            <div class="d-flex">
              <div class="d-flex flex-column me-2">
                <label class="col-form-label">Program</label>
                <div style="width: 250px">
                  <v-select
                    label="name"
                    v-model="program"
                    :options="programs"
                    :clearable="false"
                    :selectable="
                      (options) => program.short_name != options.short_name
                    "
                    @option:selected="findBatch_programFilter(program)"
                  >
                  </v-select>
                </div>
              </div>
              <div class="d-flex flex-column me-2">
                <label class="col-form-label">Batch</label>
                <div style="width: 180px">
                  <v-select
                    label="batch_no"
                    v-model="batch"
                    :options="batchs"
                    :clearable="false"
                    :selectable="(options) => batch.id != options.id"
                    @option:selected="FilterData(program, batch)"
                  >
                  </v-select>
                </div>
              </div>
              <div class="d-flex mt-4">
                <!-- <div class="col-form-label d-none">hfhd</div> -->
                <div @click="refreshData()" class="icon-css">
                  <i style="color: #4a81d4" class="fe-rotate-cw"></i>
                </div>
              </div>
            </div>
            <!--end advance serach -->

            <div class="d-flex justify-content-end">
              <router-link
                :to="{ name: 'trainer-presentation-assessment-create' }"
              >
                <button
                  v-show="!loading"
                  type="button"
                  class="btn btn-sm btn-blue waves-effect waves-light mb-2"
                  v-if="
                    hasPermissions(['trainer-presentation-assessment-create'])
                  "
                >
                  <i class="mdi mdi-plus-circle"></i> Add Record
                </button>
              </router-link>
            </div>
            <place-holder v-if="loading"></place-holder>

            <DataTable
                v-else
                table="presentation-assessment-list-datatable"
                :data="presentationAssessment"
                :columns="column"
                route="trainer-presentation-assessment-update"
              />

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Datatable Program Allocation
import DataTable from "../../../components/shared/DataTable.vue";

import $ from "jquery";
import axios from "axios";
import { useToast } from "vue-toastification";
import { useStore } from "vuex";
import { computed } from "vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
//Mixins
import userHasPermissions from "../../../mixins/userHasPermissions";

export default {
  components: {
    vSelect,DataTable
  },
  mixins: [userHasPermissions],
  setup() {
    const toast = useToast();
    const store = useStore();

    // Access user.employee_id using computed property
    const employeeId = computed(() => store.getters["auth/getAuthEmpId"]);

    return { toast, employeeId };
  },
  data() {
    return {
      presentationAssessment: [],
      ass: [],
      month: "",
      months: [],
      year: "",
      years: [],
      yearId: "",
      programs: this.$store.getters["odoo/getAllPrograms"],
      batchs: [],
      program: "",
      batch: "",
      loading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
       
      number: 0,
      column : [
        {
            data: "id",
            title: "No",
            render: () => {
              this.number++;
              return this.number;
            },
        },
        {data :'employee_name', title:'Employee Name' },
        {data :'program_name', title:'Program Name' },
        {data :'batch_no', title:'Batch' },
        {data :'total_score', title:'Total' },
        {
          data: 'assessor_employee_id',
          title: "Action",
          render: (assessor_employee_id) => {
            
            if(assessor_employee_id == this.currentUserId)
                return `<i class="mdi mdi-square-edit-outline edit-icon hover fs-4"></i>`;
            else
                return `<i class="mdi mdi-block-helper"></i>`;
            
            
          },
        },
      ]
    };
  },
  methods: {
    isSelectableYear(options) {
      if (!this.year) return true;
      return this.year != options.id;
    },
    isSelectableMonth(options) {
      if (!this.month) return true;
      return this.month != options.id;
    },
    async getPresentationAssessment() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v2/trainer-assessment?second_type=Presentation`)
        // .get(`${this.baseUrl}admin/v2/hrd-orientation-assessment`)
        .then((response) => {
          this.presentationAssessment = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          console.log(error)
          this.errors = error.response?.data?.errors || '';
          this.toast.error("Something Went Wrong!");
        });
      $("#presentation-assessment-list-datatable").DataTable();
    },

    async getBudgetYear() {
      this.loading = true;
      await axios
        .get(`${this.baseUrl}admin/v1/yearly-training-plans`)
        .then((response) => {
          this.years = response.data.data;
          this.loading = false;
        });
    },

    async getPlansByYear(value) {
      this.yearId = value.id;
      this.month = "";
      this.training_plan_status = "";
      this.loading = true;
      this.$Progress.start();
      await this.getAllMonths(this.yearId);
    },

    async getAllMonths(year_id) {
      axios
        .get(`${this.baseUrl}admin/v1/get-months/${year_id}`)
        .then((response) => {
          let obj = response.data.data;
          this.months = Object.keys(obj).map((key) => {
            this.loading = false;
            this.$Progress.finish();
            return { id: key, name: obj[key] };
          });
        });
    },

    async getPlansByMonth(value) {
      if (this.yearId) {
        this.loading = true;
        this.$Progress.start();
        axios
          .get(
            `${this.baseUrl}admin/v2/trainer-assessment-getFilterDate?budget_year_id=${this.yearId}&month=${value.id}&type=Orientation`
          )
          .then((response) => {
            // this.assessment = response.data.assessment;

            this.presentationAssessment = response.data.data;
            this.loading = false;
            this.$Progress.finish();
            if (this.presentationAssessment.length === 0) {
              this.$nextTick(() => {
                $("#presentation-assessment-list-datatable").DataTable();
              });
            }
          });
      } else {
        this.month = "";
        this.toast.error("Please Choose Budget Year First!");
      }
    },
    async findBatch_programFilter(program) {
      this.batch = "";
      this.loading = true;
      await axios
        .get(
           `${this.baseUrl}admin/v1/program-batches/list?program_id=${program.id}`
        )
        .then((response) => {
          this.batchs = response.data.data;
          this.loading = false;
        })
        .catch(() => {
          this.toast.error("Not Found Batch!");
        });

      this.clearFilter();

      $.fn.dataTable.ext.search.push(function (settings, data) {
        const check_program = program?.name == data[2] ? true : false;
        return check_program ? 1 : 0;
      });
      $("#presentation-assessment-list-datatable").DataTable().draw();
    },
    FilterData(program, batch) {
      this.clearFilter();
      // alert('reach');
      $.fn.dataTable.ext.search.push(function (settings, data) {
        const check_program = program?.name == data[2] ? true : false;
        const check_batch = batch?.batch_no == data[3] ? true : false;

        return check_program && check_batch  ? true : false;
      });
      $("#presentation-assessment-list-datatable").DataTable().draw();
    },
    refreshData() {
      (this.orientationAssessment = []), (this.ass = []), (this.month = "");
      this.year = "";
      this.months = [];
      this.number = 0;
      this.years = [];
      this.yearId = "";
      this.program = "";
      this.batchs = [];
      this.batch = "";
      this.clearFilter();
      this.getBudgetYear();
      this.getPresentationAssessment();
    },

    clearFilter() {
      $.fn.dataTable.ext.search.pop();
      $("#presentation-assessment-list-datatable").DataTable().draw();
    },
  },
  computed: {
    currentUserId() {
      return this.$store.state.auth.user.employee_id;
    },
  },
  created() {
    this.clearFilter();
    this.getBudgetYear();
    this.getPresentationAssessment();
  },
};
</script>

<style>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4a81d4;
  border-color: #4a81d4;
}
.status {
  padding: 0.4em;
}
</style>
